 import{
    CommsProvider,
    ThemeProvider,
    useSession
  } from '@dolbyio/comms-uikit-react';
 import VoxeetSDK from '@voxeet/voxeet-web-sdk';
 import React, { useEffect, useState,useCallback } from 'react';
 import {UNITY_URLS} from './utils/config';
 import "react-tooltip/dist/react-tooltip.css";
 import { Unity, useUnityContext } from "react-unity-webgl";
 import FileDropForm from "./components/modal"
//  import axios from 'axios';
//  import io from 'socket.io-client';
//  import ChatApp from './components/chat';
//  import ZoomMeeting from './components/zoom';
 import Content from './components/video/VideoUI'

    async function getAccessToken() {
        //const response = await fetch("http://localhost:5000/dolbyauth");
       const response = await fetch("https://dolbyauth.holofair.io/dolby")
        const json = await response.json();
        //console.log("AAL")
        console.log(json.access_token)
       // token = await json.access_token;
        return json.access_token;
      }

   
    const AppBase = ({ children }) => {
    
        useEffect(() => {
          localStorage.setItem("markInt","");
          localStorage.setItem("markSpeaker","");
          localStorage.setItem('joinedInp','')
          let isSubscribed = true;
         if(isSubscribed)
             return () => {
             isSubscribed = false
          };
          }, []); 

        return (
          <ThemeProvider>
            <CommsProvider token={getAccessToken} refreshToken={getAccessToken}>
              {children}
            </CommsProvider>
          </ThemeProvider>
        );
      };

 

  const App = () => {
    const [conferenceUID, setConferenceUID] = useState()
    const [u, setusern] = useState()
    const [m, setmeetingn] = useState()
    const [r, setrole] = useState('')
    const [isOpenbtn, setIsopen] = useState(false)
    const[isCall,setCall] = useState()
    const { closeSession } = useSession('')
    const [error, setError] = useState()
    const [progress, setProgress] = useState(0);
    const { unityProvider, sendMessage, addEventListener, removeEventListener, loadingProgression,isLoaded } = useUnityContext(UNITY_URLS);
    const [openPromot,setOpenPromot] = useState(false);

    function funcOpen(e){
      setIsopen(e)
    }
    function clearError(){
       console.log("Trigger Clear Error")
        setError('');
    }

  //   const connect = useCallback( async (username,roomname,lang,role)=> {
  //    localStorage.setItem('ppid','')
  //   //  if(localStorage.getItem('leaveSession') === 'yes'){
  //   //   console.log('yes')
  //   //   return;
  //   //   }

  //  // else{
  //     setCall(false)
      
  //     try {
  //       //closeSession()
  //       VoxeetSDK.conference.leave()
  //       console.log(`Connecting using data`, )
  //       setusern(username)
  //       setmeetingn(roomname)
  //       setrole(role)

  //       if (VoxeetSDK.conference.current) {
  //         alert('Already in a session. Please leave the current session before joining another.');
  //         return;
  //       }
  //       await VoxeetSDK.session.open({
  //         name: username,
  //         userRole: '1'
  //       });
    
  //       console.log(`Connected`)
  //       try {
  //         console.log(`Creating conference`)
  //         const conference = await VoxeetSDK.conference.create({
  //           alias: roomname
  //         });
  //         console.log(`Joining conference`)
  //         await VoxeetSDK.conference.join(conference, {
            
  //           // simulcast: true,
  //           constraints: {
  //             audio: false,
  //             video: false
  //           }
  //         });
         
  //         console.log(`Joined conference`)
  //         console.log(conference.id)
  //         setConferenceUID(conference.id)
  //        // setInsert(conference.id,role);
  //         // setIsopen(true)
  //         // setCall(true)


  //         if(progress >= 100){
  //           setProgress(0)
  //           setIsopen(true)
  //           setCall(true)
          
  //         }

  //         localStorage.setItem('callstatus','Already in a call')

  //       } catch (error) {
  //         console.log('Something went wrong')
  //         console.log(error);
  //       }
  //     } catch (e) {
  //       console.error('Connection failed, handle error', e);
  //     }
  //  // }
  //   },[progress])


  const cleanSession = async () => {
    try {
        // Check if a session is currently open
        if (VoxeetSDK.session.isOpen()) {
            console.log('Session is open, closing it now...');
            await VoxeetSDK.conference.leave(); // Leave the current conference if any
            await VoxeetSDK.session.close(); // Close the session
            console.log('Session closed successfully.');
            setError('')
        } else {
            console.log('No active session to clean.');
        }
    } catch (error) {
        console.error('Error during session cleanup:', error);
    }
};



  const connect = useCallback(async (username, roomname, lang, role) => {
    localStorage.setItem('ppid', '');
    setCall(false);
    
    try {
        // // Check if there's an active session and leave it if necessary
        // if (VoxeetSDK.conference.current) {
        //     console.log('Already in a session, leaving it first...');
        //     await VoxeetSDK.conference.leave();
        //     await VoxeetSDK.session.close(); 
        //     console.log('Left the current session.');
        // }

        await cleanSession();

        console.log('Connecting using data...');
        setusern(username);
        setmeetingn(roomname);
        setrole(role);

        try {
            // Open a new session
            await VoxeetSDK.session.open({
                name: username,
                userRole: '1',
            });
            console.log('Session opened successfully.');
        } catch (sessionError) {
            console.error('Failed to open session:', sessionError);
            throw new Error('Session opening failed');
        }

        try {
            console.log('Creating conference...');
            const conference = await VoxeetSDK.conference.create({
                alias: roomname,
            });

            console.log('Joining conference...');
            await VoxeetSDK.conference.join(conference, {
                constraints: {
                    audio: false,
                    video: false,
                },
            });

            console.log('Joined conference successfully.');
            console.log('Conference ID:', conference.id);
            setConferenceUID(conference.id);

            if (progress >= 100) {
                setProgress(0);
                setIsopen(true);
                setCall(true);
            }

            localStorage.setItem('callstatus', 'Already in a call');
        } catch (conferenceError) {
            console.error('Failed to create or join conference:', conferenceError);
            throw new Error('Conference creation/joining failed');
        }
    } catch (error) {
        console.error('Connection failed or something went wrong:', error);
        setError(error)
    }
}, [progress]);


    


    const joinDolbyfunc = useCallback((username, roomname,lang, role)=>{
      
      console.log("My data",username, roomname,lang, role)
      //  var myrole = role === 0 ? 'speaker' :role
         var myrole = role
          if(localStorage.getItem('callstatus') !== '' && VoxeetSDK.session.isOpened === true){
            alert("You cannot make another call while you are already in a call")
            
          }else{
            connect(username,roomname,lang,myrole)
            }

        },[connect]);


    
        const leaveDolbyfunc = useCallback(()=>{
          console.log("Leaving...")
          setError('')
          setOpenPromot(false)
        
      
        },[])


    const joinCall = useCallback((username, roomname,lang, role) =>{
      console.log(username, roomname,lang, role)
      setOpenPromot(false)
      joinDolbyfunc(username, roomname,lang, role);
    },[joinDolbyfunc])

    const DismissCall = useCallback(()=> {
      setOpenPromot(false)
    },[])

    const showPromot = ()=>{
      setOpenPromot(true)
    }



    const loadingPercentage = Math.round(loadingProgression * 100);


    const onLoadAction = () => {
        // isOpenbtn === true ? setIsopen(false) : setIsopen(true);
        localStorage.setItem('callstatus','')
    }



  const receiveMessageUnity = useCallback((event) =>{
    
    //console.log("message event", event);
    var message = event.data;
    // const y = searchParams.get('uuid').substring(5)
    // console.log(y)
    //console.log("type", event.origin);
    var whitelisted = ["https://stgmyhctwebapp.hct.ac.ae", "https://hcttest.holofair.io", "https://myhctapps.hct.ac.ae", "https://eduverse.hct.ac.ae"];
    if (whitelisted.includes(event.origin)) {
      if (message.startsWith('uuid:')) {
        var uuid = message.substring(5);
        //console.log("receiveMessage", uuid);
        if(unityProvider){
          sendMessage("App", "SetUUID", uuid);
        } else {
         // console.error("No unity instance was found.");
        }
      }
    }
  },[sendMessage, unityProvider])


    useEffect(()=> {
      localStorage.setItem('callstatus','')

      if(localStorage.getItem("progress") !== '') {
        localStorage.setItem("progress","") 
        setProgress(0)
      }


      const updateProgress = () => {
        setProgress(oldProgress => {
            if (oldProgress === 100) {
                clearInterval(timer);
                setCall(true)
                setIsopen(true)
                localStorage.setItem("progress",progress)
                return 100;
            }
            
            return Math.min(oldProgress + 10, 100);
        });
      //  console.log(progress)
      
    };

    const timer = isCall === false ?setInterval(updateProgress, 200):0;

    addEventListener("joinDolby",joinDolbyfunc);
   // addEventListener("joinDolby",showPromot);
    window.addEventListener('message', receiveMessageUnity, false);
    window.addEventListener('beforeunload', onLoadAction);
    addEventListener("leaveDolby",leaveDolbyfunc);
    window.addEventListener('beforeunload', leaveDolbyfunc);
    
      return () => {
        removeEventListener("joinDolby",joinDolbyfunc)
       // removeEventListener("joinDolby",showPromot)
        removeEventListener("leaveDolby",leaveDolbyfunc);
        window.removeEventListener('beforeunload', leaveDolbyfunc);
        window.removeEventListener('message', receiveMessageUnity, false);
        window.removeEventListener('beforeunload', onLoadAction);
        clearTimeout(timer);
        // clearInterval(timer)
      }

    },[addEventListener, isCall, joinDolbyfunc, leaveDolbyfunc, m, progress, receiveMessageUnity, removeEventListener, u])
    
    const unityCtrlOff = () => {
      console.log('clicked off');
      sendMessage("App","ToggleWebGLInputAutoCapturing",0)
    };

    const closeErrAlert = () => {
      setError('')
    }
   

   
    return (
      <>
    
        <React.StrictMode>
        <AppBase>
        <FileDropForm  myCall={sendMessage} />
        {/* <ChatApp  myChat={sendMessage} myEvent={addEventListener} myCleanUp={removeEventListener} /> */}
          {isCall === false  && progress < 100 &&
            <div className='spinnerCall'>
                <div>
                  <progress style={{width:"350px",height:"5px"}} value={progress} max="100"></progress>
                  <p style={{textAlign:"center", background: "#011226",width: "45px",margin: "auto",color: "#fff", borderRadius: "50px"}}
                  >{progress}%</p>
                </div>
            </div>
             } 
        {error && isCall == true &&
            <div className='spinnerCall'>
            <div>
              
              <p style={{textAlign:"center", background: "#980000",width: "410px",height: "47px", margin: "",color: "#fff", borderRadius: "50px"}}
              >The call has not started. Something went wrong <button id="errorAlert" onClick={closeErrAlert}
                style={{
                  border: "1px solid",
                  borderRadius: "50px",
                  width: "25px",
                  display:"inline-grid",
                  height: "25px",
                  background: "unset",
                  color: "#fff",
                  lineHeight: "0",
                  marginTop: "11px",
                  marginLeft: "3px"
                }}>
                  
                X</button></p>
            </div>
        </div>

        }
 {openPromot &&
   <div className="button-container">
        <button className="dismiss-btn" onClick={DismissCall}>Dismiss</button>
        <button className="join-btn" onClick={joinCall}>
            Join the call
            <span className="call-icon">
            
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 2.75C0.25 2.08696 0.513392 1.45107 0.982233 0.982233C1.45107 0.513392 2.08696 0.25 2.75 0.25L3.89333 0.25C4.61 0.25 5.235 0.738333 5.40917 1.43333L6.33 5.11917C6.40462 5.4175 6.38955 5.73122 6.28667 6.02102C6.18379 6.31082 5.99769 6.56383 5.75167 6.74833L4.67417 7.55667C4.56167 7.64083 4.5375 7.76417 4.56917 7.85C5.03948 9.12904 5.78216 10.2906 6.74579 11.2542C7.70941 12.2178 8.87096 12.9605 10.15 13.4308C10.2358 13.4625 10.3583 13.4383 10.4433 13.3258L11.2517 12.2483C11.4362 12.0023 11.6892 11.8162 11.979 11.7133C12.2688 11.6105 12.5825 11.5954 12.8808 11.67L16.5667 12.5908C17.2617 12.765 17.75 13.39 17.75 14.1075L17.75 15.25C17.75 15.913 17.4866 16.5489 17.0178 17.0178C16.5489 17.4866 15.913 17.75 15.25 17.75L13.375 17.75C6.12667 17.75 0.25 11.8733 0.25 4.625L0.25 2.75Z" fill="white"/>
                    </svg>
                    
            
            </span>
        </button>
    </div>
  }
           
        {isOpenbtn && conferenceUID &&
          <Content  update={funcOpen} usern={u} mname={m} id={conferenceUID} role={r}  videoTag={sendMessage} unityCtrl={unityCtrlOff} clearError={clearError} /> 
        } 
       {isLoaded === false && (
       <div className="progress-overlay">
         <div className="progress-bar">
         <h2>{loadingPercentage}%</h2>
         <progress style={{margin:"0 auto",width:"80%",height:"50%",border:"1px solid #fff"}} id="file2" value={loadingPercentage} max="100">({loadingPercentage}%)</progress>
         </div>
       </div> 
       )} 
       <div  onClick={unityCtrlOff}>
       <Unity  style={{ width: "100%",height:"100vh" , visibility: isLoaded ? "visible" : "hidden"}} unityProvider={unityProvider} />
       </div>

       { /*<ZoomMeeting controls={sendMessage} zoomEstablish={addEventListener} zoomClose={removeEventListener} /> */}

       {/* <div onClick={handleClick23} id="meetingSDKElement">
       </div> */}
        </AppBase>
        </React.StrictMode>
      </>
    )
  }

  export default App;